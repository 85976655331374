import React, { useEffect, useState } from "react";
import { Chip, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link, useNavigate } from "react-router-dom";
import UserResources from "../api/UserResources";
import useLoadingBackdrop from "../hooks/useLoadingBackdrop";
import { TablePagination } from "@mui/material";
import { Pagination, Select, MenuItem } from "@mui/material";
import { useSelector } from "react-redux/es/hooks/useSelector";

export default function Users({ PageLabel, isStudent, isDashboard }) {
    const userInfo = useSelector((state) => state.user.userInfo);
    const { LoadingBackdrop } = useLoadingBackdrop();
    const [loading, setLoading] = useState(false);
    const [data, dataSet] = useState([]);
    const [paginatedData, paginatedDataSet] = useState([]);
    const [currentPage, currentPageSet] = useState(1);
    const [perPageItem, perPageItemSet] = useState(99);

    const navigate = useNavigate();
    const isAdmin = userInfo.role_type == 1;

    // Fetch all users
    const UserList = async (params) => {
        var params = {
                page: currentPage,
                limit: perPageItem,
            },
            params;

        setLoading(true);
        try {
            const res = await UserResources.user_list(params);

            if (res.status) {
                // Filter students from all users if
                if (isStudent) {
                    const students = res.data.data.filter(
                        (u) => u.role_type == 4
                    );
                    dataSet(students);
                } else {
                    dataSet(res.data.data);
                }
                paginatedDataSet(res.data.paginate_data);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        UserList();
    }, [isStudent, currentPage, perPageItem]);

    function handleChangePage(event, newpage) {
        perPageItemSet(newpage);
    }

    function handleChangeRowsPerPage(event) {
        perPageItemSet(parseInt(event.target.value));
        currentPageSet(0);
    }

    // console.log('currentPage', currentPage)

    const handlePageChange = (page) => {
        currentPageSet(page);
    };

    const handlePerPageChange = (event) => {
        perPageItemSet(event.target.value);
        currentPageSet(1);
    };

    const TableDataRow = ({ row }) => (
        <TableRow key={row.id}>
            <TableCell>{row.id}</TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>
                <Chip
                    size="small"
                    label={
                        row.role_type == "1"
                            ? "Admin"
                            : row.role_type == "2"
                            ? "Management"
                            : row.role_type == "3"
                            ? "Teacher"
                            : row.role_type == "4"
                            ? "Student"
                            : "Unknown Role"
                    }
                    color="primary"
                />
            </TableCell>
            <TableCell>{row.email}</TableCell>
            <TableCell>{row.phone}</TableCell>
            <TableCell>
                <Box sx={{ display: "flex", gap: 1 }}>
                    <Link to={`/enrollment/${row.id}`} state={{ data: row }}>
                        <Button
                            variant="contained"
                            disableElevation
                            size="small"
                            color="secondary"
                        >
                            View
                        </Button>
                    </Link>

                    {isAdmin && (
                        <Button
                            variant="contained"
                            disableElevation
                            size="small"
                            disabled={row.role_type != 4}
                            onClick={() => {
                                navigate(`/create-enrollment/${row.id}`, {
                                    state: { data: row },
                                });
                            }}
                        >
                            Enroll
                        </Button>
                    )}
                </Box>
            </TableCell>
        </TableRow>
    );

    return (
        <>
            <Typography variant="h4" gutterBottom>
                {PageLabel}
            </Typography>

            {loading && <LoadingBackdrop />}

            {!loading && data && data.length > 0 && (
                <TableContainer component={Paper} px={30}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Phone</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isDashboard
                                ? data &&
                                  data.map((row, index) => (
                                      <TableDataRow key={index} row={row} />
                                  ))
                                : data &&
                                  data
                                      .slice(0, 3)
                                      .map((row, index) => (
                                          <TableDataRow key={index} row={row} />
                                      ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <TablePagination
                rowsPerPageOptions={[10, 30, 60, 90, 120, 150]}
                component="div"
                count={paginatedData.total}
                rowsPerPage={perPageItem}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            {/* {!loading && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "right",
                        my: 3,
                        alignItems: "center",
                    }}
                >
                    <Select
                        value={perPageItem}
                        onChange={handlePerPageChange}
                        size="small"
                    >
                        <MenuItem value={5}>5 per page</MenuItem>
                        <MenuItem value={10}>10 per page</MenuItem>
                        <MenuItem value={15}>15 per page</MenuItem>
                        <MenuItem value={150}>150 per page</MenuItem>
                    </Select>

                    <Pagination
                        variant="outlined"
                        color="primary"
                        count={paginatedData.last_page}
                        page={currentPage}
                        onChange={(event, page) => handlePageChange(page)}
                    />
                </Box>
            )} */}
        </>
    );
}
