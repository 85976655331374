import React, { useState, useEffect } from "react";
import { Chip, Typography } from "@mui/material";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { TablePagination } from "@mui/material";
import UserResources from "../api/UserResources";

export default function AllCourseList({ PageLabel }) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [paginationData, paginationDataSet] = useState([]);
    const [pg, setpg] = React.useState(1);
    const [rpg, setrpg] = React.useState(10);

    console.log("paginationData", paginationData);

    function handleChangePage(event, newpage) {
        setpg(newpage);
    }

    function handleChangeRowsPerPage(event) {
        setrpg(parseInt(event.target.value));
        setpg(0);
    }

    const CourseList = async (params) => {
        // e.preventDefault();
        setLoading(true);
        var params = {
            limit: rpg,
            page: pg,
        };

        try {
            const res = await UserResources.getCourseList(params);
            if (res.status) {
                setData(res.data.data);
                paginationDataSet(res.data.paginate_data);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };
    useEffect(() => {
        CourseList();
    }, [rpg, pg]);

    return (
        <>
            <Typography variant="h4" gutterBottom>
                {PageLabel}
            </Typography>
            <TableContainer component={Paper} px={30}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>S. No.</TableCell>

                            <TableCell>Name</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Fee</TableCell>
                            <TableCell>Duration</TableCell>
                            {/* <TableCell>Payment</TableCell> */}

                            {/* <TableCell>Action</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading &&
                            // data.map((row, index) => (
                            data.map((row, index) => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>

                                    <TableCell>{row.name}</TableCell>

                                    <TableCell>
                                        <Chip
                                            label={
                                                row.status == 1
                                                    ? "Active"
                                                    : "NA"
                                            }
                                            color="primary"
                                            variant="outlined"
                                        />
                                    </TableCell>

                                    <TableCell>{row.fee}</TableCell>

                                    <TableCell>
                                        {row.duration_in_months} Month
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[10, 30, 60, 90, 120, 150]}
                component="div"
                count={paginationData.total}
                rowsPerPage={rpg}
                page={pg}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
}
