import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import { NotificationsActiveOutlined } from "@mui/icons-material";

export default function Notifications() {
	return (
		<List sx={{ width: "100%", bgcolor: "background.paper" }}>
			<ListItem alignItems="flex-start">
				<ListItemAvatar>
					<NotificationsActiveOutlined />
				</ListItemAvatar>
				<ListItemText
					primary="New Lead received."
					secondary={
						<React.Fragment>
							<Typography
								sx={{ display: "inline" }}
								component="span"
								variant="body2"
								color="text.primary"
							/>
							Lorem ipsum dolor sit amet consectetur adipisicing
							elit. Veniam ullam voluptatibus quis animi debitis
							nisi error praesentium dolores corrupti.
						</React.Fragment>
					}
				/>
			</ListItem>
			<Divider variant="inset" component="li" />
			<ListItem alignItems="flex-start">
				<ListItemAvatar>
					<NotificationsActiveOutlined />
				</ListItemAvatar>
				<ListItemText
					primary="Lorem dolor sit amet.."
					secondary={
						<React.Fragment>
							<Typography
								sx={{ display: "inline" }}
								component="span"
								variant="body2"
								color="text.primary"
							/>
							New enrollment eos unde aliquam alias quia magnam
							doloremque nam, consequuntur voluptate vitae labore
							consectetur.
						</React.Fragment>
					}
				/>
			</ListItem>
		</List>
	);
}
