import React from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";

import { Box, Typography, Chip, Card, CardContent } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import UserResources from "../api/UserResources";
import { useState } from "react";

import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useRef } from "react";

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

export default function CreateClassroom() {
    const formRef = useRef();

    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [courseData, setCourseData] = React.useState([]);
    const [batchData, setBatchData] = React.useState([]);
    const [teacherData, setTeacherData] = React.useState([]);
    const [studentData, setStudentData] = React.useState([]);

    const [batch, setBatch] = React.useState("");
    const [student, setStudent] = React.useState([]);
    const [teacher, setTeacher] = React.useState("");
    const [course, setCourse] = React.useState("");

    const [loading, loadingSet] = React.useState(false);
    const [openSnackbar, openSnackbarSet] = React.useState(false);
    const [message, setMessage] = React.useState("");

    const createClassroom = async (params) => {
        var params = {
            batch_id: batch,
            course_id: course,
            teacher_id: teacher,
            student_ids: student,
        };
        console.log("qwertyu----", formRef.current);
        try {
            const res = await UserResources.saveUpdateClassroom(params);
            // console.log("------status", res.status);

            // console.log("------status.data", res.data);

            if (res.status) {
                console.log("1", 1);
                openSnackbarSet(true);
                setMessage("Course created successfully!");
                console.log("1", 1);
                formRef.current.reset();
            }
        } catch (error) {
            console.log(error);
            openSnackbarSet(true);
            setMessage("Something went wrong! Please try later.");
        }
    };

    const getBatch = async (params) => {
        loadingSet(true);
        var params = {
                page: "",
                limit: 99,
            },
            params;
        try {
            const res = await UserResources.getBatchList(params);

            // console.log("response Batch", res.data.data);

            if (res.status) {
                setBatchData(res.data.data);
                loadingSet(false);

                // console.log("status---", res.status);
                // formRef.current.reset();
            }
        } catch (error) {
            console.log(error);
        }
        loadingSet(false);
    };
    React.useEffect(() => {
        getBatch();
    }, []);

    const getCourse = async (params) => {
        loadingSet(true);
        var params = {
                page: "",
                limit: 99,
            },
            params;
        try {
            const res = await UserResources.getCourseList(params);

            if (res.status) {
                setCourseData(res.data.data);
                // formRef.current.reset();
            }
        } catch (error) {
            console.log(error);
        }
        loadingSet(false);
    };

    React.useEffect(() => {
        getCourse();
    }, []);

    const getTeacherList = async (params) => {
        loadingSet(true);
        var params = {
                page: "",
                limit: 99,
            },
            params;
        try {
            const res = await UserResources.user_list(params);
            // console.log("response Teacher-->", res.data.data);
            if (res.status) {
                setTeacherData(res.data.data);

                // formRef.current.reset();
            }
        } catch (error) {
            console.log(error);
        }
        loadingSet(false);
    };
    React.useEffect(() => {
        getTeacherList();
    }, []);

    const getStudentList = async (params) => {
        var params = {
                page: "",
                limit: "99",
                role_type: 4,
            },
            params;
        try {
            const res = await UserResources.user_list(params);

            if (res.status) {
                setStudentData(res.data.data);

                // formRef.current.reset();
            }
        } catch (error) {
            console.log(error);
        }
    };
    React.useEffect(() => {
        getStudentList();
    }, []);

    const handleChangeMultiple = (event) => {
        const {
            target: { value },
        } = event;
        setStudent(typeof value === "string" ? value.split(",") : value);
    };

    return (
        <Box sx={{ p: 2 }}>
            <Typography
                variant="h4"
                gutterBottom
                style={{ textAlign: "center" }}
            >
                Create Classroom
            </Typography>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={(e) => openSnackbarSet(false)}
                message={message}
                TransitionComponent={TransitionUp}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={(e) => openSnackbarSet(false)}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />

            <Grid container spacing={3} maxWidth={"sm"} margin={"auto"}>
                <Grid item xs={12} sm={12}>
                    <Box
                        component={"form"}
                        onSubmit={(e) => {
                            e.preventDefault();
                            createClassroom();
                        }}
                        ref={formRef}
                    >
                        <Card>
                            <CardContent>
                                <Box mb={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                            Select Batch
                                        </InputLabel>
                                        <Select
                                            // margin="normal"
                                            required
                                            fullWidth
                                            id="batch"
                                            label="Select Batch"
                                            name="batch"
                                            autoComplete="batch"
                                            autoFocus
                                            value={batch}
                                            onChange={(e) =>
                                                setBatch(e.target.value)
                                            }
                                        >
                                            {!loading &&
                                                batchData &&
                                                batchData.length > 0 &&
                                                batchData.map((item, index) => (
                                                    <MenuItem
                                                        value={item.id}
                                                        key={index}
                                                    >
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box mb={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                            Select Course
                                        </InputLabel>
                                        <Select
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="course"
                                            label="Select Course"
                                            name="course"
                                            autoComplete="course"
                                            autoFocus
                                            value={course}
                                            onChange={(e) =>
                                                setCourse(e.target.value)
                                            }
                                        >
                                            {!loading &&
                                                courseData &&
                                                courseData.length > 0 &&
                                                courseData.map(
                                                    (cours, index) => (
                                                        <MenuItem
                                                            value={cours.id}
                                                            key={index}
                                                        >
                                                            {cours.name}
                                                        </MenuItem>
                                                    )
                                                )}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box mb={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                            Select Teacher
                                        </InputLabel>
                                        <Select
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="teacher"
                                            label=" Select Teacher"
                                            name="teacher"
                                            autoComplete="teacher"
                                            autoFocus
                                            value={teacher}
                                            onChange={(e) =>
                                                setTeacher(e.target.value)
                                            }
                                        >
                                            {!loading &&
                                                teacherData &&
                                                teacherData.length > 0 &&
                                                teacherData.map(
                                                    (item, index) => (
                                                        <MenuItem
                                                            value={item.id}
                                                            key={index}
                                                        >
                                                            {item.name}
                                                        </MenuItem>
                                                    )
                                                )}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box mb={2}>
                                    <FormControl sx={{ width: "100%" }}>
                                        <InputLabel id="demo-multiple-name-label">
                                            Select Students
                                        </InputLabel>
                                        <Select
                                            labelId="demo-multiple-name-label"
                                            id="demo-multiple-name"
                                            multiple
                                            value={student}
                                            onChange={handleChangeMultiple}
                                            input={
                                                <OutlinedInput label="Select Students" />
                                            }
                                        >
                                            {studentData &&
                                                studentData.map((item) => (
                                                    <MenuItem
                                                        key={item.id}
                                                        value={item.id}
                                                    >
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Box>

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    disableElevation
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Create
                                </Button>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
            </Grid>

            {/* <Dialog open={open} onClose={handleClose} minWidth={"md"} fullWidth>
            <DialogTitle>Follow Up</DialogTitle>
            <DialogContent>
                <DialogContentText>
						Update the
					</DialogContentText>
                <Box py={3}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                            Select Status
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={age}
                            label="Age"
                            onChange={handleChange}
                        >
                            <MenuItem value={10}>Call Not Received</MenuItem>
                            <MenuItem value={20}>Call Later</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <TextField
                    autoFocus
                    margin="dense"
                    multiline
                    id="name"
                    label="Description"
                    type="email"
                    fullWidth
                    variant="standard"
                />
            </DialogContent>
            <DialogActions
                sx={{ pb: 3, px: 3, justifyContent: "space-between" }}
            >
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    disableElevation
                    onClick={handleClose}
                >
                    Update
                </Button>
            </DialogActions>
            </Dialog> */}
        </Box>
    );
}
