import React, { useState, useEffect } from "react";
import { Chip, Typography, Box } from "@mui/material";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FollowUpModal from "./FollowUpModal";
import Button from "@mui/material/Button";

import { TablePagination } from "@mui/material";
import UserResources from "../api/UserResources";
import { Link, useNavigate } from "react-router-dom";
import DialerBreakResume from "./DialerBreakResume";
import DialerResource from "../api/DialerResource";

export default function LeadList({ PageLabel }) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [paginationData, paginationDataSet] = useState([]);
    const [pg, setpg] = React.useState(1);
    const [rpg, setrpg] = React.useState(10);
    const [open, setOpen] = React.useState(false);
    // const [age, setAge] = React.useState("");
    const [leadID, setLeadId] = React.useState("");

    function handleChangePage(event, newpage) {
        setpg(newpage);
    }

    function handleChangeRowsPerPage(event) {
        setrpg(parseInt(event.target.value));
        setpg(0);
    }
    // const handleChange = (event) => {
    //     setAge(event.target.value);
    // };
    const handleClose = () => {
        setOpen(false);
    };
    // const handleChange = (event) => {
    //     setAge(event.target.value);
    // };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const LeadPriority = ({ row }) => {
        const priorityMapping = {
            4: "High",
            3: "Medium",
            2: "Low",
            1: "Very Low",
        };
        const value = priorityMapping[row.priority] || "Default Value";
        return (
            <Chip
                label={value}
                color={"primary"}
                variant="outlined"
                size="small"
            />
        );
    };

    // const DialerCall = () =? {

    // }

    const CourseList = async (params) => {
        // e.preventDefault();
        setLoading(true);
        var params = {
            limit: rpg,
            page: pg,
        };

        try {
            const res = await UserResources.getLeadList(params);
            if (res.status) {
                setData(res.data.data);
                paginationDataSet(res.data.paginate_data);
                // console.log("leadid-->");
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };
    useEffect(() => {
        CourseList();
    }, [rpg, pg]);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="h4" gutterBottom>
                    {PageLabel}
                </Typography>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate("/upload-leads")}
                        sx={{ marginRight: 5 }}
                    >
                        Upload Leads
                    </Button>

                    <DialerBreakResume />
                </Box>
            </Box>
            <TableContainer component={Paper} px={30}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Lead ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Contact No.</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Location</TableCell>
                            <TableCell>Experience</TableCell>
                            <TableCell>Priority</TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading &&
                            // data.map((row, index) => (
                            data.map((row, index) => (
                                <TableRow key={row.id}>
                                    <TableCell>
                                        <Box sx={{ whiteSpace: "nowrap" }}>
                                            {row.id}
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        {/* <Button onClick={() => navigate('/')}>
                                            {row.name}
                                        </Button> */}
                                        <Link
                                            to={`/view-lead/${row.id}`}
                                            state={{
                                                data: row,
                                                isLeadDetails: true,
                                            }}
                                            title="Manage as admin"
                                        >
                                            {row.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{row.phone}</TableCell>
                                    <TableCell>{row.email}</TableCell>
                                    <TableCell>
                                        {row.details.location}
                                    </TableCell>
                                    <TableCell>
                                        {row.details.experience}
                                    </TableCell>
                                    <TableCell>
                                        <LeadPriority row={row} />
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ display: "flex" }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disableElevation
                                                sx={{
                                                    whiteSpace: "nowrap",
                                                    fontSize: 12,
                                                }}
                                                size="small"
                                                onClick={() => {
                                                    setLeadId(row.id);
                                                    // console.log(leadID);
                                                    handleClickOpen();
                                                }}
                                            >
                                                Follow Up
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disableElevation
                                                sx={{
                                                    whiteSpace: "nowrap",
                                                    fontSize: 12,
                                                    marginLeft: 5,
                                                }}
                                                size="small"
                                                onClick={async () => {
                                                    const ans =
                                                        await DialerResource.dialerCall(
                                                            {
                                                                src: 2151,
                                                                dst: 6390259809,
                                                                refID: "TG",
                                                            }
                                                        );
                                                    console.log("answer", ans);
                                                }}
                                            >
                                                Call
                                            </Button>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[10, 30, 60, 90, 120, 150]}
                component="div"
                count={paginationData.total}
                rowsPerPage={rpg}
                page={pg}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <FollowUpModal
                handleClose={handleClose}
                open={open}
                // handleChange={handleChange}
                leadID={leadID}
            />
        </>
    );
}
