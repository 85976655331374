import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import UserResources from "../api/UserResources";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

const useStyles = makeStyles((theme) => ({
    pageHeading: {
        marginBottom: theme.spacing(1),
        "& h1": {
            margin: theme.spacing(0),
            [theme.breakpoints.only("xs")]: {
                marginTop: theme.spacing(5.2),
                fontSize: 24,
            },
        },
    },
    btnPrimaryLight: {
        backgroundColor: "#d4def1",
        color: "#2959ba",
        border: "1px solid #d4def1",
    },
    marginBottom: {
        marginBottom: theme.spacing(1),
    },
}));

/**
 * Upload contacts in .csv format file
 *
 * @name UploadContacts
 * @alias UploadContacts
 * @component
 *
 * @param {string} csv_file
 *
 * @example
 * <UploadContacts } />
 */

export default function UploadLeads(props) {
    const classes = useStyles();
    const [success, setSuccess] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(null);
    const [message, setMessage] = React.useState("");
    const [file, setFile] = React.useState("");
    const [openSnackbar, openSnackbarSet] = React.useState(false);

    const handleClickOpen = (modalName) => {
        setOpen(modalName);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const submitUploadContacts = async (e) => {
        e.preventDefault();
        setLoading(true);

        var formData = new FormData();

        formData.append("csv", file);
        try {
            const response = await UserResources.uploadFile(formData);

            console.log("response before try", response.data);
            if (response.status) {
                setSuccess(true);
                openSnackbarSet(true);

                setMessage(response.data.message);
                setLoading(false);
            } else {
                openSnackbarSet(true);

                setMessage(response.data.message);
            }

            if (response && response.data.status === "fail") {
                setMessage(response.data.message);

                setLoading(false);
            }
        } catch (error) {
            if (error.response && error.response.status == 422) {
                if (error.response.data.errors) {
                    setMessage(error.data.message);
                }
                if (error.response.data.errors.csv_file) {
                    setMessage(error.response.data.errors.csv_file[0]);
                }
            }
        }
        setLoading(false);
    };

    return (
        <>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={(e) => openSnackbarSet(false)}
                message={message}
                TransitionComponent={TransitionUp}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={(e) => openSnackbarSet(false)}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
            <Box maxWidth={"sm"} sx={{ border: "1px solid #ddd", p: 3 }}>
                <form onSubmit={(e) => submitUploadContacts(e)} noValidate>
                    <InputLabel className={classes.marginBottom}>
                        Browse Leads File
                        <sup style={{ color: "red" }}>* .csv only</sup>
                    </InputLabel>
                    <TextField
                        variant="outlined"
                        //required
                        size="small"
                        fullWidth
                        //label="Browse Contacts File"
                        name="contactsDoc"
                        id="contactsDoc"
                        inputProps={{ accept: ".csv" }}
                        type="file"
                        onInput={(e) => {
                            setFile(
                                document.getElementById("contactsDoc").files[0]
                            );
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Box mt={2} mb={1} textAlign="center">
                        <Button
                            variant="contained"
                            fullWidth
                            align="center"
                            color="primary"
                            disableElevation
                            type="submit"
                            margin="normal"
                            disabled={loading ? true : false}
                        >
                            {loading ? "Uploading.." : "Upload"}
                        </Button>
                    </Box>
                </form>
            </Box>
        </>
    );
}
