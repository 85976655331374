import React, { useState } from "react";
import { Box, Card, CardContent, Grid, Button } from "@mui/material";
// import EnrollFrom from "../components/EnrollFrom";
import Typography from "@mui/material/Typography";
import UserResources from "../api/UserResources";
import Users from "../components/Users";
import { useNavigate } from "react-router-dom";

const AppWidgetSummary = ({ title, total, bg, color }) => {
    console.log("lol", process.env.NODE_ENV);
    return (
        <Card sx={{ background: bg }} elevation={0}>
            <CardContent sx={{ color: color }}>
                <Box sx={{ fontSize: "2rem" }}>{total}</Box>
                {title}
            </CardContent>
        </Card>
    );
};
export default function Dashboard() {
    const navigate = useNavigate();

    return (
        <>
            <Typography variant="h4" sx={{ mb: 5 }}>
                Hi, Welcome back
            </Typography>
            <Grid container spacing={3} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                        title="Total Leads"
                        total={90}
                        bg={"#2196F3"}
                        color={"#fff"}
                        icon={"ant-design:android-filled"}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                        title="Potential Leads"
                        total={60}
                        bg="#83a858"
                        color={"#fff"}
                        icon={"ant-design:apple-filled"}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                        title="Dead Leads"
                        total={15}
                        bg="#e91e63"
                        color={"#fff"}
                        icon={"ant-design:windows-filled"}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                        title="Converted Lead"
                        total={15}
                        bg="#4CAF50"
                        color={"#fff"}
                        icon={"ant-design:bug-filled"}
                    />
                </Grid>

                <Grid item sm={12} md={12}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="h5" fontWeight={"bold"}>
                            Recent Users
                        </Typography>
                        <Button
                            variant="contained"
                            disableElevation
                            onClick={() => {
                                navigate("/users");
                            }}
                        >
                            View All
                        </Button>
                    </Box>
                    <Users isDashboard={true} />
                </Grid>
            </Grid>
        </>
    );
}
