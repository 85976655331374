import TextField from "@material-ui/core/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { experimentalStyled as styled } from "@mui/material/styles";
import * as React from "react";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
import Button from "@mui/material/Button";
// import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
// import { useTheme } from "@mui/material/styles";
// import OutlinedInput from "@mui/material/OutlinedInput";
import {
	Card,
	CardContent,
	Radio,
	RadioGroup,
	FormControlLabel,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import UserResources from "../api/UserResources";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	paper: {
		// marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		// margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%",
		// marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(2),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

export default function EnrollFrom() {
	const classes = useStyles();
	const location = useLocation();
	// const queryParams = new URLSearchParams(location.search);
	const studentDetails = location.state.data;
	// console.log(studentDetails);
	//   const theme = useTheme();
	//   const [personName, setPersonName] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [courseData, courseDataSet] = React.useState([]);

	const [name, setName] = React.useState(studentDetails.name || "");
	const [nameErrorText, setNameErrorText] = React.useState("");
	const [email, setEmail] = React.useState(studentDetails.email || "");
	const [emailErrorText, setEmailErrorText] = React.useState("");

	const [password, setPassword] = React.useState("");
	const [passwordErrorText, setPasswordErrorText] = React.useState("");

	const [phone, setPhone] = React.useState(studentDetails.phone || "");
	const [phoneErrorText, setPhoneErrorText] = React.useState("");

	const [gender, setGender] = React.useState("");
	const [genderErrorText, setGenderErrorText] = React.useState("");
	const [course, setCourse] = React.useState("");
	const [courseErrorText, setCourseErrorText] = React.useState("");

	const [comment, setComment] = React.useState("");
	const [commentErrorText, setCommentErrorText] = React.useState("");

	const [zipcode, setZipcode] = React.useState("");
	const [zipErrorText, setZipErrorText] = React.useState("");

	const [state, setState] = React.useState("");
	const [stateErrorText, setStateErrorText] = React.useState("");

	const [city, setCity] = React.useState("");
	const [cityErrorText, setCityErrorText] = React.useState("");

	const [feeAmount, feeAmountSet] = React.useState(1);
	const [amount, setAmount] = React.useState("");
	const [feeErrorText, feeErrorTextSet] = React.useState("");

	const onSubmit = (e) => {
		e.preventDefault();
		console.log(e.target.value);
		if (!name) {
			setNameErrorText("Please enter Name");
		} else {
			setNameErrorText("");
		}
		if (!email) {
			setEmailErrorText("Please enter email");
		} else {
			setEmailErrorText("");
		}
		if (!password) {
			setPasswordErrorText("Please enter password");
		} else {
			setPasswordErrorText("");
		}

		if (!gender) {
			setGenderErrorText(" Please select Gender");
		} else {
			setGenderErrorText("");
		}

		if (!feeErrorText) {
			feeErrorTextSet("Please enter amount");
		} else {
			feeErrorTextSet("");
		}
		if (!phone) {
			setPhoneErrorText("Please enter number");
		} else {
			setPhoneErrorText("");
		}

		if (!course) {
			setCourseErrorText("Please enter course");
		} else {
			setCourseErrorText("");
		}
		if (!zipcode) {
			setZipErrorText("Please enter pin code");
		} else {
			setZipErrorText("");
		}

		if (!comment) {
			setCommentErrorText("Please enter comment");
		} else {
			setCommentErrorText("");
		}

		if (!state) {
			setStateErrorText("Please enter comment");
		} else {
			setStateErrorText("");
		}

		if (!city) {
			setCityErrorText("Please enter city");
		} else {
			setCityErrorText("");
		}
	};

	const CourseList = async (params) => {
		setLoading(true);
		var params = {
			limit: "",
			page: "",
		};

		try {
			const res = await UserResources.getCourseList(params);
			console.log("res", res);
			if (res.status) {
				courseDataSet(res.data.data);
			}
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	React.useEffect(() => {
		CourseList();
	}, []);

	console.log("courses", courseData);

	return (
		<Card>
			<CardContent>
				<Box
					gutterBottom
					sx={{
						textAlign: "center",
						fontSize: "2rem",
						marginBottom: 2,
					}}
				>
					Enrollment
				</Box>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<Box>
							<TextField
								variant="outlined"
								size="small"
								required
								type="name"
								fullWidth
								id="name"
								label="Name"
								name="name"
								autoComplete="name"
								value={name}
								error={!!nameErrorText}
								helperText={nameErrorText}
								onChange={(e) => setName(e.target.value)}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Box>
							<TextField
								variant="outlined"
								size="small"
								required
								type="email"
								fullWidth
								id="email"
								label="Email Address"
								name="email"
								autoComplete="email"
								value={email}
								error={!!emailErrorText}
								helperText={emailErrorText}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</Box>
					</Grid>

					<Grid item xs={12} sm={6}>
						<Box>
							<TextField
								variant="outlined"
								required
								type="number"
								fullWidth
								id="phone"
								label="Phone"
								name="phone"
								autoComplete="phone"
								size="small"
								value={phone}
								error={!!phoneErrorText}
								helperText={phoneErrorText}
								onChange={(e) => setPhone(e.target.value)}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl>
							<RadioGroup
								row
								aria-labelledby="demo-row-radio-buttons-group-label"
								name="row-radio-buttons-group"
							>
								<FormControlLabel
									id="female"
									value={1}
									control={<Radio />}
									label="Female"
									onChange={(e) => setGender(e.target.value)}
								/>
								<FormControlLabel
									id="male"
									value={2}
									control={<Radio />}
									label="Male"
									onChange={(e) => setGender(e.target.value)}
								/>
								<FormControlLabel
									id="others"
									value={3}
									control={<Radio />}
									label="Others"
									onChange={(e) => setGender(e.target.value)}
								/>
							</RadioGroup>
						</FormControl>
					</Grid>

					<Grid item xs={12} sm={6}>
						<Box>
							<FormControl fullWidth>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="city"
									label="City"
									name="city"
									size="small"
									value={city}
									error={!!cityErrorText}
									helperText={cityErrorText}
									onChange={(e) => setCity(e.target.value)}
								/>
							</FormControl>
						</Box>
					</Grid>

					<Grid item xs={12} sm={6}>
						<Box>
							<FormControl fullWidth>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="state"
									label="State"
									name="state"
									size="small"
									value={state}
									error={!!stateErrorText}
									helperText={stateErrorText}
									onChange={(e) => setState(e.target.value)}
								/>
							</FormControl>
						</Box>
					</Grid>

					<Grid item xs={12} sm={6}>
						<FormControl fullWidth size="small">
							<InputLabel id="demo-simple-select-label">
								Course
							</InputLabel>
							<Select
								required
								fullWidth
								id="Course"
								label="Course"
								name="Course"
								autoComplete="Course"
								value={course}
								onChange={(e) => setCourse(e.target.value)}
							>
								{courseData.map((item, index) => (
									<MenuItem key={index} value={item.id}>
										{item.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							size="small"
							required
							type="number"
							fullWidth
							id="zip"
							label="Pincode"
							name="zipcode"
							autoComplete="zipcode"
							value={zipcode}
							error={!!zipErrorText}
							helperText={zipErrorText}
							onChange={(e) => setZipcode(e.target.value)}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<FormControl>
							<RadioGroup
								row
								aria-labelledby="demo-row-radio-buttons-group-label"
								name="row-radio-buttons-group"
								defaultValue={1}
							>
								<FormControlLabel
									id="female"
									value={1}
									control={<Radio />}
									label="Full Payment"
									onChange={(e) =>
										feeAmountSet(e.target.value)
									}
								/>
								<FormControlLabel
									id="male"
									value={2}
									control={<Radio />}
									label="Partial Payment"
									onChange={(e) =>
										feeAmountSet(e.target.value)
									}
								/>
							</RadioGroup>
						</FormControl>
					</Grid>

					<Grid item xs={12} sm={6}>
						{feeAmount == 1 ? (
							<Box>
								<FormControl fullWidth>
									<TextField
										variant="outlined"
										required
										fullWidth
										id="city"
										label="Full Payment"
										name="city"
										size="small"
										value={amount}
										error={!!feeErrorText}
										helperText={feeErrorText}
										onChange={(e) =>
											setAmount(e.target.value)
										}
									/>
								</FormControl>
							</Box>
						) : (
							<Box>
								<FormControl fullWidth>
									<TextField
										variant="outlined"
										required
										fullWidth
										id="city"
										label="Partials Payment Amount"
										name="city"
										size="small"
										value={amount}
										error={!!feeErrorText}
										helperText={feeErrorText}
										onChange={(e) =>
											setAmount(e.target.value)
										}
									/>
								</FormControl>
							</Box>
						)}
					</Grid>

					<Grid item xs={8} sm={12}>
						<TextField
							variant="outlined"
							required
							type="comment"
							fullWidth
							id="comment"
							size="small"
							label="Add Comment"
							name="comment"
							autoComplete="comment"
							multiline
							minRows={3}
							value={comment}
							error={!!commentErrorText}
							helperText={commentErrorText}
							onChange={(e) => setComment(e.target.value)}
						/>
					</Grid>

					<Grid item md={12}>
						<Box sx={{ textAlign: "right" }}>
							<Button
								type="submit"
								// fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
								onClick={onSubmit}
								disableElevation
							>
								Submit
							</Button>
						</Box>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}
