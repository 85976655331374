import React from "react";
import { Chip, Typography } from "@mui/material";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect } from "react";
import { TablePagination } from "@mui/material";
import UserResources from "../api/UserResources";
import { useState } from "react";
import dateFormat from "dateformat";

export default function Classroom({ PageLabel }) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [perpage, setPerPage] = useState(99);
    const [paginationData, paginationDataSet] = useState([]);

    function handleChangePage(event, newpage) {
        setPage(newpage);
    }

    function handleChangeRowsPerPage(event) {
        setPerPage(parseInt(event.target.value));
        setPage(0);
    }
    const ClassroomList = async (params) => {
        setLoading(true);
        var params = Object.assign(
            {
                limit: perpage,
                page: page,
            },
            params
        );
        try {
            const res = await UserResources.get_classroom_list(params);
            // console.log("res-->", res);
            // console.log("datares-->", res.data);

            if (res.status) {
                setData(res.data.data);
                paginationDataSet(res.data.paginate_data);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };
    useEffect(() => {
        ClassroomList();
    }, []);

    return (
        <>
            <Typography variant="h4" gutterBottom>
                {PageLabel}
            </Typography>
            <TableContainer component={Paper} px={30}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>S. No.</TableCell>

                            <TableCell>Class Name</TableCell>

                            <TableCell>Course</TableCell>
                            <TableCell>Teacher</TableCell>
                            <TableCell>Created at</TableCell>

                            {/* <TableCell>Action</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading &&
                            data.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>

                                    <TableCell>{row.batch.name}</TableCell>

                                    <TableCell>{row.course.name}</TableCell>
                                    <TableCell>{row.teacher.name}</TableCell>
                                    {/* <TableCell>{row.teacher.name}</TableCell> */}
                                    <TableCell>
                                        {dateFormat(
                                            row.created_at,
                                            "dd mmm, yyyy"
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[10, 30, 60, 90, 120, 150]}
                component="div"
                count={paginationData.total}
                rowsPerPage={perpage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
}
