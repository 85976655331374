import axios from "axios";

const DialerResource = {
    dialerLogin: (params) => {
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(
            `https://cloud.casq.in/ConvoqueAPI/uLogin.jsp?${queryString}`
        );
    },
    dialerBreak: (params) => {
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(
            `https://cloud.casq.in/ConvoqueAPI/uPause.jsp?${queryString}`
        );
    },

    dialerResume: (params) => {
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(
            `https://cloud.casq.in/ConvoqueAPI/uResume.jsp?${queryString}`
        );
    },
    dialerLogout: (params) => {
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(
            `https://cloud.casq.in/ConvoqueAPI/uLogout.jsp?${queryString}`
        );
    },
    dialerCall: (params) => {
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(
            `https://cloud.casq.in/ConvoqueAPI/placeACall.jsp?${queryString}`
        );
    },
};
export default DialerResource;
