import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import DialerResource from "../api/DialerResource";

const DialerBreakResume = () => {
    const [alignment, setAlignment] = React.useState("web");

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const Break = async () => {
        try {
            const ans = await DialerResource.dialerBreak({
                userID: "mngmnt@mailinator.com",
                reason: "break",
            });
            console.log("ans break", ans);
        } catch (error) {
            console.log(error);
        }
    };

    const Resume = async () => {
        try {
            const ans = await DialerResource.dialerResume({
                userID: "mngmnt@mailinator.com",
            });
            console.log("ans Resume", ans);
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <ToggleButtonGroup
            color="secondary"
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
        >
            <ToggleButton
                value="break"
                onClick={() => {
                    Break();
                }}
            >
                Break
            </ToggleButton>
            <ToggleButton
                value="resume"
                onClick={() => {
                    Resume();
                }}
            >
                Resume
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

export default DialerBreakResume;
