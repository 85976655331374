import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import AddTeacher from "./components/AddTeacher";
import AllCourseList from "./components/AllCourse";
import BatchSingle from "./components/BatchSingle";
import Classroom from "./components/Classroom";
import CreateBatch from "./components/CreateBatch";
import CreateClassroom from "./components/CreateClassroom";
import CreateCourse from "./components/CreateCourse";
import EnrollFrom from "./components/EnrollFrom";
import FollowUps from "./components/FollowUps";
import Layout from "./components/Layout";
import LeadList from "./components/LeadList";
import LeadSingle from "./components/LeadSingle";
import ListInTableView from "./components/ListInTableView";
import Login from "./components/Login";
import Notifications from "./components/Notifications";
import Password from "./components/Password";
import Profile from "./components/Profile";
import Register from "./components/Register";
import SideBar from "./components/SideBar";
import TableValues from "./components/Table";
import Users from "./components/Users";
import Dashboard from "./screens/Dashboard";
import LeadForm from "./screens/LeadForm";
import Leads from "./screens/Leads";
import UploadLeads from "./components/UploadLeads";

const rows = [
    {
        name: "Stock market",
        contact: 12344444423,
        status: "Active",
        teacher: "Tony Stark",
        email: "aj@example.com",
    },
    {
        name: "Financial advisor",
        contact: 12344444423,
        teacher: "Bruce Wayne",
        status: "Inactive",
        email: "seema.ra@example.com",
    },
];
const courses = [
    {
        name: "Stock market",
        contact: 12344444423,
        status: "Active",
        teacher: "Tony Stark",
        email: "aj@example.com",
    },
    {
        name: "Financial advisor",
        contact: 12344444423,
        teacher: "Bruce Wayne",
        status: "Inactive",
        email: "seema.ra@example.com",
    },
];

// const students = [
//     {
//         name: "Ajay Singh",
//         contact: 12344444423,
//         email: "aj@example.com",
//         course: "Finance Advisor",
//         payment_status: "paid",
//     },
//     {
//         name: "Shivani Singh",
//         contact: 12344444423,
//         email: "shivani.ra@example.com",
//         course: "Trading Advisor",
//         payment_status: "Partial paid",
//     },
// ];

const enroll = [
    {
        name: "Ajay Singh",
        contact: 12344444423,
        email: "aj@example.com",
        course: "Finance Advisor",
        payment_status: "Partially Paid",
    },
    {
        name: "Shivani Singh",
        contact: 12344444423,
        email: "shivani.ra@example.com",
        course: "Trading Advisor",
        payment_status: "Fully Paid",
    },
];
const classroomData = [
    {
        className: "BAT21",
        course: "Finance Advisor",
        faculty: "Tony Stark",
    },
    {
        className: "BAT32",
        course: "Trading Advisor",
        faculty: "Bruce Wayne",
    },
];

function App() {
    const isLogin = useSelector((state) => state.user.loggedIn);
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state.user.userInfo);

    console.log("isLogin ==>", isLogin);
    console.log("userInfo ==>", userInfo);

    React.useEffect(() => {
        if (isLogin) {
            return navigate("/dashboard");
        }

        // return navigate("/register");
    }, [isLogin]);

    return isLogin ? (
        <Layout userInfo={userInfo} SideBar={<SideBar userInfo={userInfo} />}>
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/create-lead" element={<LeadForm />} />
                <Route path="/leads" element={<Leads />} />
                <Route
                    path="/view-lead/:id"
                    element={<LeadSingle userType={userInfo.role_type} />}
                />
                <Route path="/view-batch/:id" element={<BatchSingle />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/password" element={<Password />} />
                <Route path="/create-batch" element={<CreateBatch />} />
                <Route path="/notifications" element={<Notifications />} />

                <Route
                    path="/all-batches"
                    element={
                        <ListInTableView
                            PageLabel={"All Batches"}
                            rows={rows}
                        />
                    }
                />
                <Route path="/create-course" element={<CreateCourse />} />
                <Route
                    path="/all-courses"
                    element={
                        <AllCourseList
                            PageLabel={"All Courses"}
                            userType={userInfo.role_type}
                            rows={rows}
                        />
                    }
                />
                <Route
                    path="/all-leads"
                    element={<LeadList PageLabel={"All Leads"} />}
                />
                <Route
                    path="/upload-leads"
                    element={<UploadLeads PageLabel={"Upload Leads"} />}
                />
                <Route
                    path="/students"
                    element={
                        <Users
                            PageLabel={"All Students"}
                            isStudent={true}
                            userType={userInfo}
                        />
                    }
                />
                <Route
                    path="/users"
                    element={<Users PageLabel={"All Users"} />}
                />
                <Route path="/follow-ups" element={<FollowUps />} />
                <Route path="/create-classroom" element={<CreateClassroom />} />
                <Route
                    path="/all-classroom"
                    element={
                        <Classroom
                            PageLabel={"Classrooms"}
                            rows={classroomData}
                            isClassroom={true}
                        />
                    }
                />
                <Route path="/add-teacher" element={<AddTeacher />} />
                <Route
                    path="/create-enrollment/:id"
                    element={<EnrollFrom userType={userInfo.role_type} />}
                />
                <Route
                    path="/all-enrollment"
                    element={
                        <ListInTableView
                            PageLabel={"All Enrollment"}
                            rows={enroll}
                            isEnrollment={true}
                            isStudent={true}
                        />
                    }
                />
                <Route
                    path="/enrollment/:id"
                    element={<LeadSingle isEnrollmentSingle={true} />}
                />

                <Route
                    path="/batch/:id"
                    element={<BatchSingle isBatchView={true} />}
                />
                <Route
                    path="/converted-leads"
                    element={<TableValues title={"Converted Leads"} />}
                />
            </Routes>
        </Layout>
    ) : (
        <>
            <Routes>
                <>
                    <Route path="/" element={<Login />} />
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="/register"
                        element={<Register isLogin={isLogin} />}
                    />
                    <Route path="/create-lead" element={<LeadForm />} />
                </>
            </Routes>
        </>
    );
}

export default App;
