import config from "../config";
import axios from "./base";

const api_url = config.api_url;

const UserResources = {
    register: (params) => {
        return axios.post(api_url + "/register", params);
    },
    login: (params) => {
        return axios.post(api_url + "/login", params);
    },
    logout: (params) => {
        return axios.get(api_url + "/user/logout", params);
    },

    user_list: (params) => {
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(`${api_url}/user/user-list?${queryString}`, params);
    },
    update_user_detail: (params) => {
        return axios.post(api_url + "/user/update-user-details", params);
    },
    update_user_password: (params) => {
        return axios.post(api_url + "/user/update-password", params);
    },
    saveUpdateBatch: (params) => {
        return axios.post(api_url + "/batches/save-and-update", params);
    },
    getBatchList: (params) => {
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(
            `${api_url}/batches/batches-list?${queryString}`,
            params
        );
    },
    getCourseList: (params) => {
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(
            `${api_url}/courses/courses-list?${queryString}`,
            params
        );
    },

    saveUpdateCourse: (params) => {
        return axios.post(api_url + "/courses/save-and-update", params);
    },

    getAddTeacher: (params) => {
        return axios.get(api_url + "/user/get-user-info", params);
    },

    get_classroom_list: (params) => {
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(
            `${api_url}/classroom/classroom-list?${queryString}`,
            params
        );
    },

    saveUpdateClassroom: (params) => {
        return axios.post(api_url + "/classroom/save-and-update", params);
    },

    saveUpdateLead: (params) => {
        return axios.post(api_url + "/lead/save-and-update", params);
    },
    getLeadList: (params) => {
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(`${api_url}/lead/leads-list?${queryString}`, params);
    },

    followupList: (params) => {
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(
            `${api_url}/follow-up/follow-up-list?${queryString}`,
            params
        );
    },

    saveUpdateFollowUp: (params) => {
        return axios.post(api_url + "/follow-up/save-and-update", params);
    },

    uploadFile: (params) => {
        return axios.post(`${api_url}/csv-to-database`, params, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },
};

export default UserResources;
