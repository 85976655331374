import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextsmsIcon from '@mui/icons-material/Textsms';
import RingVolumeIcon from '@mui/icons-material/RingVolume';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import PhoneIcon from '@mui/icons-material/Phone';
import { Box, Typography, Chip } from "@mui/material";
// import FollowUpModal from "./FollowUpModal";
import { TablePagination } from "@mui/material";

import UserResources from "../api/UserResources";

export default function FollowUps() {
    // const [open, setOpen] = React.useState(false);
    // const [age, setAge] = React.useState("");
    const [loading, setLoading] = useState(false);
    // const [open, setOpen] = React.useState(false);
    const [data, setData] = useState([]);
    const [paginationData, paginationDataSet] = useState([]);
    const [pg, setpg] = React.useState(1);
    const [rpg, setrpg] = React.useState(10);
    // const [leadID, setLeadId] = React.useState("");

    function handleChangePage(event, newpage) {
        setpg(newpage);
    }

    function handleChangeRowsPerPage(event) {
        setrpg(parseInt(event.target.value));
        setpg(1);
    }

    const FollowUps = async (params) => {
        setLoading(true);

        var params = {
                limit: rpg,
                page: pg,
            },
            params;

        try {
            const res = await UserResources.followupList(params);
            if (res.status) {
                setData(res.data.data);
                paginationDataSet(res.data.paginate_data);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };
    useEffect(() => {
        FollowUps();
    }, [rpg, pg]);

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Follow Ups
            </Typography>

            <TableContainer component={Paper} px={30}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Lead ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Contacts</TableCell>
                            <TableCell>Current Status</TableCell>
                            <TableCell>Follow Ups</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading &&
                            data.map((row, index) => (
                                <TableRow key={index}>

                                    <TableCell>{row.lead_id}</TableCell>

                                    <TableCell>{row.lead.name}</TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle2" sx={{display: 'flex', alignItems: 'center'}}>
                                            <ForwardToInboxIcon sx={{fontSize: 12, marginRight: 1}}/> {row.lead.email}
                                        </Typography>
                                        <Typography variant="subtitle2" sx={{display: 'flex', alignItems: 'center'}}>
                                            <PhoneIcon sx={{fontSize: 12, marginRight: 1}}/>{row.lead.phone}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Chip
                                            label={
                                                row.status == 1
                                                    ? "Call Later"
                                                    : "Call Not Received"
                                            }
                                            sx={{height: '20px', fontSize: '13px'}}
                                            color="primary"
                                            variant="outlined"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle2" sx={{display: 'flex', alignItems: 'center'}}>
                                            <TextsmsIcon sx={{fontSize: 12, marginRight: 1}}/>{row.description}
                                        </Typography>
                                        <Typography variant="subtitle2" sx={{display: 'flex', alignItems: 'center', fontSize: 12, }}>
                                            <RingVolumeIcon sx={{fontSize: 12, marginRight: 1}}/>{row.next_date}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[10, 30, 60, 90, 120, 150]}
                component="div"
                count={paginationData.total}
                rowsPerPage={rpg}
                page={pg}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
}
