let config = null;

const local = {
    api_url: "https://trading-game-backend.jycyberhub.com/api",
};
const production = {
    api_url: "https://api.tradinggamestrong.com/api",
};

config = local;

console.log("lol", process.env.NODE_ENV);

if (process.env.NODE_ENV == "local") {
    config = local;
}
if (process.env.NODE_ENV == "production") {
    config = production;
}

console.log({ config });

export default config;
