import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
// import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import UserResources from "../api/UserResources";
import { login, setUserInfo } from "../store/userSlice";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import DialerResource from "../api/DialerResource";
import { FormControl } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

const defaultTheme = createTheme();

const Login = () => {
    const extentionNumber = [
        2151, 2152, 2153, 2154, 2155, 2156, 2157, 2158, 2159, 2160,
    ];
    const dispatch = useDispatch();
    // const navigate = useNavigate();
    const [message, messageSet] = useState("");
    const [open, setOpen] = useState(false);
    const [loading, loadingSet] = useState(false);
    const [success, successSet] = useState(false);
    const [dialerLoginSuccess, dialerLoginSuccessSet] = useState(false);
    const [dialerLoginLoading, dialerLoginLoadingSet] = useState(false);
    const [email, emailSet] = useState("");
    const [password, passwordSet] = useState("");
    const [extention, setExtention] = useState("");

    const handleRegistrationClick = () => {
        window.location.href = "/register";
    };

    const Login = async (e) => {
        loadingSet(true);
        e.preventDefault();

        try {
            const res = await UserResources.login({
                email: email,
                password: password,
            });

            if (res.status) {
                LoginDialer({ email, extention });
                successSet(true);
                setOpen(true);
                messageSet(res.message);
                dispatch(login(true));
                dispatch(setUserInfo(res.data.data));
            }
        } catch (error) {
            setOpen(true);
            messageSet(error.message);
        }
        loadingSet(false);
    };

    const LoginDialer = async ({ email, extention }) => {
        dialerLoginLoadingSet(true);
        try {
            const res = await DialerResource.dialerLogin({
                userID: email,
                phoneNo: extention,
            });
            if (res.status) {
                dialerLoginSuccessSet(true);
                console.log(res.message);
            }
        } catch (error) {
            console.log(error.message);
        }
        dialerLoginLoadingSet(false);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ height: "100vh" }}>
                <CssBaseline />

                <Snackbar
                    open={open}
                    autoHideDuration={3000}
                    onClose={(e) => setOpen(false)}
                    message={message}
                    TransitionComponent={TransitionUp}
                    action={
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={(e) => setOpen(false)}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />

                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage:
                            "url(https://source.unsplash.com/random/800x800/?quote)",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: (t) =>
                            t.palette.mode === "light"
                                ? t.palette.grey[50]
                                : t.palette.grey[900],
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                />
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={5}
                    component={Paper}
                    elevation={6}
                    square
                >
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box
                            component="form"
                            noValidate
                            onSubmit={Login}
                            sx={{ mt: 1 }}
                        >
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={(e) => emailSet(e.target.value)}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={(e) => passwordSet(e.target.value)}
                            />

                            {/* <FormControl>
								<RadioGroup
									row
									aria-labelledby="demo-row-radio-buttons-group-label"
									name="row-radio-buttons-group"
								>
									<FormControlLabel
										id="counsellor"
										value={2}
										control={<Radio />}
										label="Counsellor"
										onChange={(e) =>
											setRoleType(e.target.value)
										}
									/>
									<FormControlLabel
										id="headcounsellor"
										value={3}
										control={<Radio />}
										label="Head Counsellor"
										onChange={(e) =>
											setRoleType(e.target.value)
										}
									/>
									<FormControlLabel
										id="admin"
										value={4}
										control={<Radio />}
										label="Admin"
										onChange={(e) =>
											setRoleType(e.target.value)
										}
									/>
								</RadioGroup>
							</FormControl> */}

                            <FormControl fullWidth>
                                <InputLabel
                                    variant="standard"
                                    htmlFor="uncontrolled-native"
                                >
                                    Select Extention No
                                </InputLabel>
                                <NativeSelect
                                    inputProps={{
                                        name: "extention number",
                                        id: "uncontrolled-native",
                                    }}
                                >
                                    {extentionNumber.map((item, index) => {
                                        return (
                                            <option value={item}>{item}</option>
                                        );
                                    })}
                                </NativeSelect>
                            </FormControl>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disableElevation
                                disabled={loading}
                            >
                                Sign In
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link
                                        href="/register"
                                        onClick={handleRegistrationClick}
                                        variant="body2"
                                    >
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid>
                            {/* <Copyright sx={{ mt: 5 }} /> */}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default Login;
