import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import dateFormat from "dateformat";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import UserResources from "../api/UserResources";

import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const Wrapper = ({ className, children }) => {
    return (
        <div style={{ color: "#fff" }}>
            <CalendarContainer className={className}>
                <div style={{ background: "#f0f0f0", padding: "4px 8px" }}>
                    Select Date and Time
                </div>
                <div style={{ position: "relative" }}>{children}</div>
            </CalendarContainer>
        </div>
    );
};
const DateTime = ({ date, setDate }) => {
    return (
        <DatePicker
            selected={date}
            className="inputCss"
            onChange={(date) => setDate(date)}
            showTimeSelect
            dateFormat="MMMM d, yyyy-mm-dd H:mm:ss"
            calendarContainer={Wrapper}
        />
    );
};

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}
const defaultTheme = createTheme();
export default function FollowUpModal({
    handleClose,
    // handleChange,
    // status,

    open,
    leadID,
}) {
    console.log("leadId", leadID);
    const [date, setDate] = React.useState(new Date());
    const [message, setMessage] = React.useState("");
    const [openvalue, setOpenValue] = React.useState(false);
    const [des, setDes] = React.useState("");
    const [status, setStatus] = React.useState("");
    // console.log("leadID===", leadID);

    const statusVar = [
        {
            name: "Call Later",
            value: 1,
        },
        {
            name: "Call Not Recived",
            value: 2,
        },
    ];

    const handleFollowup = async (e) => {
        e.preventDefault();
        const newDate = dateFormat(date, "yyyy-mm-dd H:mm:ss");
        console.log("newDate========>", leadID);
        try {
            const res = await UserResources.saveUpdateFollowUp({
                lead_id: leadID,
                next_date: newDate,
                description: des,
                status: status,
            });
            if (res.status) {
                setMessage(res.data.message);
                setOpenValue(true);
            }

            // console.log("saveupdatevalue-->", res);
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <ThemeProvider theme={defaultTheme}>
            <Snackbar
                open={openvalue}
                autoHideDuration={3000}
                onClose={(e) => setOpenValue(false)}
                message={message}
                TransitionComponent={TransitionUp}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={(e) => setOpenValue(false)}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
            <Dialog open={open} onClose={handleClose} minWidth={"md"} fullWidth>
                <DialogTitle>Follow Up</DialogTitle>
                <DialogContent sx={{ minHeight: "320px" }}>
                    <Box component={"form"} onSubmit={handleFollowup}>
                        <Box mb={1}>
                            <FormControl fullWidth>
                                <InputLabel id="select">
                                    Select Status
                                </InputLabel>
                                <Select
                                    labelId="select"
                                    id="demo-simple-select"
                                    value={status.value}
                                    label={"Select one iten"}
                                    // onChange={handleChange}
                                    onChange={(e) => setStatus(e.target.value)}
                                >
                                    {statusVar.map((item) => {
                                        return (
                                            <MenuItem value={item.value}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box
                            mb={1}
                            display={"flex"}
                            alignItems={"center"}
                            py={3}
                        >
                            <FormLabel sx={{ mr: 1 }}>Next Followup</FormLabel>
                            <Box>
                                <DateTime date={date} setDate={setDate} />
                            </Box>
                        </Box>
                        <TextField
                            autoFocus
                            margin="dense"
                            multiline
                            id="name"
                            label="Description"
                            type="text"
                            fullWidth
                            // variant="standard"
                            onChange={(e) => setDes(e.target.value)}
                        />

                        <DialogActions
                            sx={{
                                pb: 3,
                                px: 3,
                                justifyContent: "space-between",
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                disableElevation
                                onClick={handleClose}
                                type="submit"
                            >
                                Update
                            </Button>
                        </DialogActions>
                    </Box>
                </DialogContent>
            </Dialog>
        </ThemeProvider>
    );
}
